var __jsx = React.createElement;
import React, { useState, useEffect } from 'react';
import Link from 'next/link';
import ConsoHead from '../components/header/ConsoHead';
import ConsoNavbar from '../components/header/Navbar';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import dynamic from 'next/dynamic';
var ConsoFooter = dynamic(function () {
  return import('../components/footer/ConsoSmallFooter');
}, {
  loadableGenerated: {
    webpack: function webpack() {
      return [require.resolveWeak('../components/footer/ConsoSmallFooter')];
    },
    modules: ["404.js -> " + '../components/footer/ConsoSmallFooter']
  }
});
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRobot, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight';
import { loadCartFromStorage } from '../components/utils/general/cartUtils';
import { useSelector, useDispatch } from 'react-redux';
import { saveProducts } from '../components/cart/cartSlice';
import { accountMenuList, accountLogoffMenuList } from '../components/header/mainMenuList';

var Custom404 = function Custom404() {
  var dispatch = useDispatch();
  var user = useSelector(function (state) {
    return state.user;
  });
  var cart = useSelector(function (state) {
    return state.cart.products;
  });
  useEffect(function () {
    setupCart();
  }, []);

  var setupCart = function setupCart() {
    var cartData = cart;

    if (cart && cart.length == 0) {
      var storageData = loadCartFromStorage();

      if (storageData && storageData.products) {
        cartData = storageData;
        dispatch(saveProducts({
          products: storageData.products,
          status: 'init'
        }));
      }
    }
  };

  var seo = {
    title: "Pagina nu a fost găsită",
    description: "Pagină inexistentă - conținutul nu există sau a fost eliminat.",
    canonical: "".concat(process.env.NEXT_PUBLIC_SITE_URL, "/404")
  };
  return __jsx(React.Fragment, null, __jsx(ConsoHead, {
    seo: seo
  }), __jsx(ConsoNavbar, {
    menu: user.isLoggedIn ? accountMenuList : accountLogoffMenuList,
    selected: "",
    cart: cart
  }), __jsx("div", {
    className: "mb-0 pb-0 bg-home d-flex align-items-center",
    id: "main"
  }, __jsx(Container, {
    className: "h-100"
  }, __jsx(Row, {
    className: "p-3 h-100"
  }, __jsx(Col, {
    className: "p-0 d-flex align-items-center flex-column"
  }, __jsx("div", null, __jsx(FontAwesomeIcon, {
    icon: faExclamationCircle,
    className: "red",
    size: "3x",
    height: "32",
    width: "32"
  })), __jsx("h1", {
    className: "heading1 font-weight-bold text-center black-text mb-0 py-3"
  }, "Pagina nu este disponibil\u0103"), __jsx("div", null, "Ne pare r\u0103u. Pagina c\u0103utat\u0103 nu exist\u0103 sau con\u021Binutul ei a fost eliminat de pe website. Verific\u0103 dac\u0103 linkul pe care \xEEncerci s\u0103-l deschizi este corect."), __jsx("p", {
    className: "mt-3"
  }, __jsx(Link, {
    href: process.env.NEXT_PUBLIC_WEB
  }, __jsx("a", {
    className: "btn btn-secondary"
  }, "Mergi pe Homepage"))), __jsx("p", {
    className: ""
  }, __jsx(Link, {
    href: "/"
  }, __jsx("a", {
    className: ""
  }, "Contul meu"))), __jsx("p", null, __jsx(Link, {
    href: "/asigurari-rca"
  }, __jsx("a", {
    className: ""
  }, "Asigur\u0103ri RCA"))))))), __jsx(ConsoFooter, null));
};

export default Custom404;